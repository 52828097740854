import React, { useState } from 'react'

import {
  Client,
} from '../shared'

import { Switch } from '@headlessui/react'

const DiningOptions = ({
  diningOptions,
  locationID
}) => {

  const client = new Client

  const [options, setOptions] = useState(diningOptions)

  const handleChange = (optionType) => {
    const newOptions = {
      ...options,
      [optionType]: !options[optionType]
    }
    if (Object.values(newOptions).every(option => !option)) return
    setOptions(newOptions)
    client.updateLocation(locationID, {
      meta: newOptions
    })
  }


  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div class="card border-light mb-3">
      <p className='text-xl'><strong>Dining Options</strong></p>
      <div class="card-body">
        <p style={{ fontSize: 20 }}>Turn on dining options you would like offer on the kiosk</p>
        <br />
        <Switch.Group as="div" className="flex items-center">
          <Switch
            checked={options.dine_in}
            onChange={() => handleChange('dine_in')}
            className={classNames(
              options.dine_in ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
            )}
          >
            <span className="sr-only">Dine In</span>
            <span
              aria-hidden="true"
              className={classNames(
                options.dine_in ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3 text-lg">
            <span className="font-medium text-gray-900">Dine In</span>

          </Switch.Label>
        </Switch.Group>
        <Switch.Group as="div" className="flex items-center">
          <Switch
            checked={options.take_out}
            onChange={() => handleChange('take_out')}
            className={classNames(
              options.take_out ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
            )}
          >
            <span className="sr-only">take out</span>
            <span
              aria-hidden="true"
              className={classNames(
                options.take_out ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3 text-lg">
            <span className="font-medium text-gray-900">Take Out</span>

          </Switch.Label>
        </Switch.Group>
      </div>
    </div>
  )

}

export default DiningOptions