import React, {useState} from 'react'
import DateTimePicker from 'react-datetime-picker'
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import {
  Client,
  formatPrice,
  BaseCard,
  styles,
} from '../shared'

const ReportsIndex = ({ locationID }) => {
  const [startTime, startTimeChange] = useState(new Date())
  const [endTime, endTimeChange] = useState(new Date())
  const [reportData, setReportData] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [orderID, setOrderID] = useState(null)

  const marginLeft = {marginLeft: 0}
  const client = new Client

  const columns = [
    {
      name: 'Gamsa Order ID',
      id: 'order_id',
      sortable: true,
      cell: row => row.order_id
    },
    {
      name: 'Status',
      id: 'status',
      sortable: true,
      cell: row => row.status
    },
    {
      name: 'Order Total',
      id: 'total',
      sortable: true,
      cell: row => formatPrice(row.total)
    },
    {
      name: 'Tax',
      id: 'tax',
      cell: row => formatPrice(row.tax)
    },
    {
      name: 'Items',
      id: 'items',
      sortable: true,
      cell: row => row.items.map(item => item.item_name).join(', ')
    }
  ]

  return (
    <BaseCard>
      <InputGroup className="mb-3">
        <Form>
          <Form.Label>
            Keep the date range narrow for best results
          </Form.Label>
          <Row>
            <h3>Date Range</h3>
            <Col>
              <Row style={marginLeft}>
                Start Time:
              </Row>
              <Row>
                <DateTimePicker onChange={startTimeChange} value={startTime} />
              </Row>
            </Col>
            <Col>
              <Row style={marginLeft}>
                End Time:
              </Row>
              <Row>
                <DateTimePicker onChange={endTimeChange} value={endTime} />
              </Row>
            </Col>
            <Col>
            </Col>
          </Row>

          <br/>

          <p> - OR -</p>

          <br/>

          <Form.Label>Order ID</Form.Label>
          <Form.Control
            type="text"
            value={orderID}
            onChange={ (e) => setOrderID(e.target.value) }
          />

          <br/>

          <Button
            disabled={submitting}
            onClick={() => {
              const starting = Math.round(startTime / 1000)
              const ending = Math.round(endTime / 1000)

              setSubmitting(true)
              client.createReport(locationID, starting, ending, orderID)
                .then(data => {
                  setReportData(data)
                })
                .finally(()=> setSubmitting(false))
            }}
          >
            Submit
          </Button>
        </Form>

      </InputGroup>
      <Row>
        {
          reportData === null ? (
            <div>No orders in this time range</div>
          ) : (
            <BaseCard>
              <h3>Orders during time range</h3>
              <DataTable
                fixedHeader
                customStyles={styles.dataTableStyles}
                columns={columns}
                data={reportData}
                pagination
              />
            </BaseCard>
          )
        }
      </Row>

    </BaseCard>
  )
}

export default ReportsIndex
